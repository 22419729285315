import React from 'react';
import {Link} from 'react-router-dom';
import '../styles/component_styles/DropdownMenu.css';
import ReorderIcon from '@mui/icons-material/Reorder';

function DropdownMenu() {
  return (
    <div className="dropdown">
      <button className='menu-button'><ReorderIcon /></button>
      
      <div className="dropdown-menu">
        <li className='link'><Link to='/music'>Music</Link></li>
        <li className='link'><Link to='/gallery'>Gallery</Link></li>
        <li className='link'><Link to='/shows'>Shows</Link></li>
        <li className='link'><Link to='/about'>About</Link></li>
        <li className='link'><Link to='/contact'>Contact</Link></li>
      </div>

    </div>
  );
}

export default DropdownMenu;