import React, { useState } from 'react';
import '../styles/Gallery.css';
import {GalleryPics} from '../components/PhotoGallery';
import BackToTopButton from '../components/BackToTopButton';
import CloseIcon from '@mui/icons-material/Close';

function Gallery() {

  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState('');
  
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  }
  
  return (
    <div className='gallery'>

      <div className={model ? 'model open' : 'model'}>
          <img src={tempImgSrc} alt="" />
          <CloseIcon onClick={() => setModel(false)} />
      </div>

      {GalleryPics.map((item, index) => {
        return(
          <div className='pictures' key={index} onClick={ () => getImg(item.imgSrc) }>
            <img src={item.imgSrc} style={{width: '100%'}} alt='' />
          </div>
        )
      })}

    <BackToTopButton />
    </div>
  )
}

export default Gallery;