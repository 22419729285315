import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/component_styles/ContactForm.css';

// Email.js Submit Form API
const ContactForm = () => {
  const form = useRef();
    
  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs
      .sendForm(
          'service_09v3xri',
          'cwr_submission_form',
          form.current,
          'reVzkLE1RtANlGgZR')
      .then(
        (result) => {
          console.log(result.text);
          console.log("Message sent. Thank you!");
          e.target.reset(); // resets the form after submitting
      }, 
      (error) => {
            console.log(error.text);
      });
  }

  return(
    <form ref={form} onSubmit={sendEmail}>
      <label><h3>Name</h3></label>
      <input 
        type="text"
        name='user_name'
        title=''
        required
        />

      <label><h3>Email</h3></label>
      <input 
        type="email"
        name='user_email'
        title=''
        required
        />
            
      <label><h3>Subject</h3></label>
        <input 
          type="text"
          name='subject'
          title=''
          required
          />

      <label><h3>Message</h3></label>
      <textarea
        name='message'
        rows='5'
        required
        title=''
        >

      </textarea>

      <input className='button' type="submit" value="Send!" />
    </form>
  );

}



export default ContactForm;