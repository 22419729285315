import React from 'react';
import HomePic from '../assets/pic_smolder.JPG';
import NewLogo from '../assets/new_logo.png';
import '../styles/Home.css';

function Home() {
  return (
    <div className='home' style={{ backgroundImage: `url(${HomePic})` }}>
      
      <div className='logo'>
        <img src={NewLogo} alt="logo" />        
      </div>
    
    </div>
  )
}

export default Home;