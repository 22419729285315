import pic1 from '../assets/gallery/pic1.JPG';
import pic2 from '../assets/gallery/pic2.JPG';
import pic3 from '../assets/gallery/pic3.JPG';
import pic6 from '../assets/gallery/pic6.JPG';
import pic7 from '../assets/gallery/pic7.JPG';
import pic8 from '../assets/gallery/pic8.JPG';
import pic9 from '../assets/gallery/pic9.JPG';
import pic10 from '../assets/gallery/pic10.JPG';
import pic11 from '../assets/gallery/pic11.JPG';
import pic12 from '../assets/gallery/pic12.jpg';
import pic13 from '../assets/gallery/pic13.jpg';
import pic14 from '../assets/gallery/pic14.JPG';
import pic15 from '../assets/gallery/pic15.JPG';
import pic16 from '../assets/gallery/pic16.JPG';
import pic17 from '../assets/gallery/pic17.JPG';
import pic18 from '../assets/gallery/pic18.JPG';
import pic19 from '../assets/gallery/pic19.JPG';
import pic20 from '../assets/gallery/pic20.JPG';
import pic21 from '../assets/gallery/pic21.JPG';

import arch from '../assets/pic_arch.JPG';
import smolder from '../assets/pic_smolder.JPG';

export const GalleryPics = [
    { id: 1, imgSrc: pic1 },
    { id: 2, imgSrc: pic7 },
    { id: 3, imgSrc: pic3 },
    { id: 4, imgSrc: pic12},
    { id: 5, imgSrc: pic13},
    { id: 6, imgSrc: pic6 },
    { id: 7, imgSrc: pic8 },
    { id: 8, imgSrc: pic9 },
    { id: 9, imgSrc: pic10},
    { id: 10, imgSrc: pic11},
    { id: 11, imgSrc: pic2 },
    { id: 12, imgSrc: arch },
    { id: 13, imgSrc: smolder },
    { id: 14, imgSrc: pic14 },
    { id: 15, imgSrc: pic15 },
    { id: 16, imgSrc: pic16 },
    { id: 17, imgSrc: pic17 },
    { id: 18, imgSrc: pic18 },
    { id: 19, imgSrc: pic19 },
    { id: 20, imgSrc: pic20 },
    { id: 21, imgSrc: pic21 },
    // { id: , imgSrc: pic },
  ];