import React from 'react';
import '../styles/component_styles/Footer.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YoutubeIcon from '@mui/icons-material/YouTube';

function Footer() {
  return (
    <div className='footer'>
        <div className='social-media'>
            <a href="https://www.facebook.com/castawayradio" target="_blank" rel="noreferrer"><FacebookIcon /></a>
            <a href="https://www.instagram.com/castawayradio" target="_blank" rel="noreferrer"><InstagramIcon /></a>
            <a href="https://www.youtube.com/@CastawayRadio" target="_blank" rel="noreferrer"><YoutubeIcon /></a>
        </div>
        <p> 
          &copy;{new Date().getUTCFullYear()} Castaway Radio
          <br />
          website by Austin Rominger
        </p>
    </div>
  )
}

export default Footer;