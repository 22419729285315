import React from 'react';
import '../styles/About.css';
import BackToTopButton from '../components/BackToTopButton';
import kickinWing from '../assets/about_kickinWing.JPG';
import onStage from '../assets/about_onStage.JPG';

function About() {
  return (
    <div className='about'>
      <h1 className='title'>About Castaway Radio</h1>
      
      <section className='blue'>
        <div className='section-1'>
          <img src={kickinWing} alt="there should be something here..." />
          
          Castaway Radio is based out of Pearland, TX since 2015, with brothers Austin and Holden Rominger at the heart and soul - melody and rhythm - with multiple members and contributors along the way. 
          Their sound can best be described as a dynamic, riff-driven indie/alt/retro style that tells rock to go funk itself. Their ever-changing, eclectic influence of sound can only be described as their own, and worth tuning into!
        </div>
        
        {/* Curve must remain in bottom of 'section' */}
        <div className='curve'></div>
      </section>

      <section className='orange'>
        <div className='section-2'>
          <img src={onStage} alt="there should be something here..." />
          
          After releasing 'Wolves' and 'Shades, Spades, & Spinach' in 2016, they ventured on a short Texas tour. 
          Record labels give them notice, with their opening for international act (now Eurovision finalists) Piqued Jacks, as well as headlining other Houston and Galveston events. 
          CWR would also gain recognition from Houston radio personalities like Dean & Rog and Sam Malone and farther from home's Dallas' GOBL.
          <br /><br />
          Their Good Stuff EP is the first of a spunky collection of some of their harder hitting songs. 
          It garnered a bit of international attention, putting them #2 on England Revolution's 96.2 with their song "Natural Disaster", as well as features from Shutter 16 Magazine and Galveston Radio. 
          In Summer 2021, they played their first festival, AsheJam, in Asheville, North Carolina and can boast as the only band invited to play all 3 days with a feature spot on one of their stages.
          <br /><br />
          They are currently in the studio working on their next full-length album, which will exhibit the same CWR sound and style while truly showcasing their maturation as songwriters.
          
        </div>
      </section>

    <BackToTopButton />
    </div>
  );
}

export default About;