import React from 'react';
import ContactForm from '../components/ContactForm';
import '../styles/Contact.css';

function Contact() {

  return (
    <div className='contact'>

      <div className='form-wrapper'>
        <h1>Contact Us!</h1>
        
        <div className='contact-form' >
          <ContactForm />
        </div>

      </div>
      
    </div>
  )
}


export default Contact;