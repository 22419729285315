import React from 'react'
import '../styles/Music.css';

function Music() {
  return (
    <div className='music-background'>

      <div className='bandcamp'>
          <div className='wolves'>
            <iframe
              title="Wolves EP"
              src="https://bandcamp.com/EmbeddedPlayer/album=988765882/size=large/bgcol=333333/linkcol=ffffff/" 
              seamless>
              <a href="https://castawayradio.bandcamp.com/album/wolves-ep">
                Wolves EP by Castaway Radio
              </a>
            </iframe>
          </div>
          
          <div className='good-stuff'>
            <iframe
              src="https://bandcamp.com/EmbeddedPlayer/album=2501981227/size=large/bgcol=333333/linkcol=4ec5ec/"
              title="Good Stuff EP"
              seamless>
              <a href="https://castawayradio.bandcamp.com/album/good-stuff">
                Good Stuff by Castaway Radio
              </a>
            </iframe>
          </div>
      </div>
      
    </div>        
  )
}

export default Music