import React, { useEffect, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import '../styles/component_styles/BackToTopButton.css'

function BackToTopButton() {

    const [tippyTopButton, setTippyTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if( window.scrollY > 500 ) {
                setTippyTopButton(true)
            }
            
            else {
                setTippyTopButton(false)
            }
        })
    },[])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return (
        <div className="up-arrow">
            {tippyTopButton && (
                <div onClick={scrollUp}>
                    <ArrowUpwardIcon />
                </div>
            )}
        </div>
    );
}

export default BackToTopButton;